import Button from "../../../components/Button/Button"
import Embed from "../../../components/YouTube/Embed"

function Fractions() {
    return (
        <div className="m-8">
            <Embed id="qmfXyR7Z6Lk" />
            <br />
            <Button link="/learn/fractions/quiz">Next</Button>
        </div>
    );
}

export default Fractions;