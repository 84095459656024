function Hero(props) {
  return (
    <div className="relative m-8 bg-gradient-to-b from-[#F9B7FE] to-[rgba(249,183,254,0.6)] p-24 py-44 text-center rounded-2xl poppins -z-20">
        <svg className="invisible md:visible h-16 opacity-60 absolute top-16 left-12 fill-[#9006D5]" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50"/>
        </svg>
        <svg className="invisible md:visible h-16 opacity-30 absolute top-16 left-12 fill-[#9006D5]" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50"/>
        </svg>
        <svg className="invisible md:visible h-12 opacity-30 absolute top-36 left-32 fill-[#9006D5]" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50"/>
        </svg>
        <svg className="invisible md:visible h-12 opacity-10 absolute top-72 left-10 fill-[#9006D5]" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50"/>
        </svg>
        <svg className="invisible md:visible h-5/6 absolute bottom-0 right-[-27px] -z-10" width="358" height="429" viewBox="0 0 358 429" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.50012 428.5C2.50012 428.5 23.0001 270.5 95.0001 186.5C167 102.5 356.5 2.5 356.5 2.5" stroke="white" strokeWidth="5" strokeDasharray="10 10"/>
        </svg>
        <div>
            {props.children}
        </div>
    </div>
  );
}

export default Hero;
