import { NavLink } from "react-router-dom";
import Button from "../Button/Button"

function Navbar() {
  return (
    <div className="navbar text-black flex px-8 justify-between items-center mt-8">
        <h1 className="font-bold w-0 md:w-4/12 text-base md:text-xl invisible md:visible"><NavLink to="/">Numeracy</NavLink></h1>
        <ul className="flex justify-center w-4/12">
            <li className="mx-3 text-base md:text-lg"><NavLink to="/">Home</NavLink></li>
            <li className="mx-3 text-base md:text-lg"><NavLink to="/learn">Learn</NavLink></li>
            <li className="mx-3 text-base md:text-lg"><a href="mailto: jamesblair@rhs.school.nz">Contact</a></li>
        </ul>
        <div className="flex w-4/12 justify-end">
          <Button link="/learn">Learn</Button>
        </div>
    </div>
  );
}

export default Navbar;
