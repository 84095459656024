import Navbar from "./components/Navbar/Navbar";
import {
  Route, 
  Routes
} from "react-router-dom";

import About from "./pages/About";
import Home from "./pages/Home";
import Learn from "./pages/Learn/Learn";
import Bedmas from "./pages/Learn/Bedmas/Bedmas";
import BedmasQuiz from "./pages/Learn/Bedmas/Quiz";
import Division from "./pages/Learn/Division/Division";
import Fractions from "./pages/Learn/Fractions/Fractions";
import DivisionQuiz from "./pages/Learn/Division/Quiz";
import FractionsQuiz from "./pages/Learn/Fractions/Quiz";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/learn" element={<Learn />} />
        <Route path="/learn/bedmas" element={<Bedmas />} />
        <Route path="/learn/bedmas/quiz" element={<BedmasQuiz />} />
        <Route path="/learn/division" element={<Division />} />
        <Route path="/learn/division/quiz" element={<DivisionQuiz />} />
        <Route path="/learn/fractions" element={<Fractions />} />
        <Route path="/learn/fractions/quiz" element={<FractionsQuiz />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<p>404 Not Found</p>} />
      </Routes>
    </div>
  );
}

export default App;
