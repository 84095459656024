import { NavLink } from "react-router-dom";

function Button(props) {
    return (
        <NavLink to={props.link}>
            <button className="px-5 md:px-10 py-3 bg-[#9006D5] rounded-lg text-white font-bold">{props.children}</button>
        </NavLink>
    );
}
  
export default Button;