import Quiz from "../../../components/Quiz/Quiz"

const questions = [
  {
      questionText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis facilisis mauris tellus interdum tincidunt at.',
      answerOptions: [
      { answerText: '1', isCorrect: false },
      { answerText: '2', isCorrect: true },
      { answerText: '3', isCorrect: false },
      { answerText: '4', isCorrect: true },
      ],
      justification: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam leo non in lobortis amet. Vel adipiscing egestas amet id sed id. At facilisis a varius viverra mattis. Elementum imperdiet nunc, velit cras pellentesque duis imperdiet vulputate sed. Leo, et natoque enim diam aliquet aliquet."
  }
];

function FractionsQuiz() {
    return (
        <div className="m-8">
            <Quiz questions={questions}/>
        </div>
    );
}

export default FractionsQuiz;