import Hero from "../components/Hero/Hero"
import Card from "../components/Card/Card"
import CardContainer from "../components/Card/CardContainer"

function Home() {
  return (
    <div>
      <Hero>
        <h2 className="text-2xl font-bold mb-5">Learn Numeracy!</h2>
        <p>An interactive website for learning Numeracy!</p>
      </Hero>
      <h2 className="text-2xl text-center font-bold mt-20 mb-10">How does it work?</h2>
      <CardContainer>
        <Card>
          <h2 className="text-3xl mb-3 font-bold">1</h2>
          <p>Find something you need to learn.</p>
        </Card>
        <Card>
          <h2 className="text-3xl mb-3 font-bold">2</h2>
          <p>Watch the embedded video.</p>
        </Card>
        <Card>
          <h2 className="text-3xl mb-3 font-bold">3</h2>
          <p>Answer some practice questions!</p>
        </Card>
      </CardContainer>
    </div>
  );
}

export default Home;
