import React, { useState } from 'react';

function Quiz(props) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showNext, setShowNext] = useState(false);
  const [isLocked, setLocked] = useState(false)
  const [instantFeedback, setInstantFeedback] = useState("");
  const [justification, setJustification] = useState("");

  const questions = props.questions;

  const handleAnswerOptionClick = (isCorrect) => {
    if (isLocked) return;


    setShowNext(true);
    setLocked(true);

		if (isCorrect) {
			setInstantFeedback("Correct!");
      setJustification(questions[currentQuestion].justification)
		} else {
      setInstantFeedback("Incorrect!");
      setJustification(questions[currentQuestion].justification)
    }
	};

  const showNextButtonClick = () => { 
    setShowNext(false);
    if (currentQuestion+1 >= questions.length) {
      setInstantFeedback("You have finished the quiz. If you want to do some more numeracy learning, go to the Learn page and you can choose another category.")
      setJustification("");
      setLocked(true);
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setInstantFeedback("");
      setJustification("");
      setLocked(false);
    }
  }

  return (
    <div>
      <div className="mb-5">
        <p className="mb-5">{questions[currentQuestion].questionText}</p>
        <div>
          {questions[currentQuestion].answerOptions.map((answerOption, index) => (
            <button className="bg-[#9006D5] text-white py-3 px-24 mr-3 rounded-lg mb-3" onClick={() => handleAnswerOptionClick(answerOption.isCorrect)} key={index}>{answerOption.answerText}</button>
          ))}
        </div>
      </div>

      <div className={instantFeedback ? "visible" : "invisible"}>
        <div className="bg-[#F4F4F4] p-24 rounded-2xl whitespace-pre-line">
          <p className="mb-5">{instantFeedback}</p>
          <p className="mb-5">{justification}</p>
          {showNext ? <button className="bg-[#9006D5] text-white px-10 py-3 rounded-lg mb-3" onClick={() => showNextButtonClick()}>Next</button> : ""}
        </div>
      </div>
    </div>
  );
}

export default Quiz;