import Card from "../../components/Card/Card";
import CardContainer from "../../components/Card/CardContainer";
import Button from "../../components/Button/Button"

function Learn() {
  return (
    <div>
      <CardContainer>
        <Card>
          <h2 className="text-6xl mb-3">½</h2>
          <p className="mb-20 mx-5">Learn how to work with fractions, including simplifying fractions, and finding equivalent factions.</p>
          <Button link="/learn/fractions">Start</Button>
        </Card>
        <Card>
          <h2 className="text-6xl mb-3">÷</h2>
          <p className="mb-20 mx-5">Learn how to use long division to divide numbers which have many digits.</p>
          <Button link="/learn/division">Start</Button>
        </Card>
        <Card>
          <h2 className="text-6xl mb-3">×</h2>
          <p className="mb-20 mx-5">Learn Order of Operations, also known as BEDMAS.</p>
          <Button link="/learn/bedmas">Start</Button>
        </Card>
      </CardContainer>
    </div>
  );
}

export default Learn;
