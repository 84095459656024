import Button from "../../../components/Button/Button"
import Embed from "../../../components/YouTube/Embed"

function Division() {
    return (
        <div className="m-8">
            <Embed id="LGqBQrUYua4" />
            <br />
            <Button link="/learn/division/quiz">Next</Button>
        </div>
    );
}
  
export default Division;