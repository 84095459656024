import Button from "../../../components/Button/Button"
import Embed from "../../../components/YouTube/Embed"
function Bedmas() {
    return (
        <div className="m-8">
            <Embed id="dAgfnK528RA" />
            <br />
            <Button link="/learn/bedmas/quiz">Next</Button>
        </div>
    );
}

export default Bedmas;